import React from 'react';
import { Link } from 'rebass/styled-components';
import styled from 'styled-components';

const ArrowLink = ({
  isLeft,
  href,
  text,
  targetBlank,
  fontFamily,
}: ArrowLinkProps) => (
  <ArrowLinkWrapper
    href={href}
    target={targetBlank && '_blank'}
    fontFamily={fontFamily}
  >
    {isLeft && (
      <ArrowWrapper className="left">
        <Arrow />
      </ArrowWrapper>
    )}
    {text}
    {!isLeft && (
      <ArrowWrapper className="right">
        <Arrow />
      </ArrowWrapper>
    )}
  </ArrowLinkWrapper>
);

interface ArrowLinkProps {
  isLeft: boolean;
  href: string;
  text: string;
  targetBlank?: boolean;
  fontFamily: string;
}

const defaultProps: ArrowLinkProps = {
  targetBlank: false,
};

ArrowLink.defaultProps = defaultProps;

const ArrowLinkWrapper = styled(Link)`
  display: flex;
  align-items: center;

  &:hover {
    div {
      div {
        width: 32px;
      }
    }
  }
`;

const ArrowWrapper = styled.div`
  width: 40px;
  margin-left: 6px;
  &.left {
    transform: rotate(180deg);
    margin-right: 6px;
  }
`;

const Arrow = styled.div`
  background: black;
  height: 1px;
  width: 21px;
  margin: 0 auto;
  position: relative;
  transition: 0.3s ease-in-out;
  float: left;

  &.left-arrow {
    transform: rotate(180deg);
  }

  &:before,
  &:after {
    content: '';
    background: black;
    position: absolute;
    height: 1px;
    width: 6px;
  }

  &:before {
    right: -1px;
    bottom: -2px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -1px;
    top: -2px;
    transform: rotate(45deg);
  }
`;

export default ArrowLink;
