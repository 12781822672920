import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '@/components/Layout';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { styledTheme } from '@/styledTheme';
import TitleSection from '@/components/ProjectPage/TitleSection';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Box } from 'rebass/styled-components';
import ArrowLink from '@/components/ArrowLink';

const ProjectPage = ({ data, pageContext }: ProjectPageProps) => {
  const {
    title,
    tags,
    date,
    link,
    client,
    format,
    credits,
    featuredImage,
    footerImage,
    footerImage2,
    whiteHeader,
    isTransparent,
    linkUrl,
    badgeImage,
    embeddedImagesLocal,
  } = data.mdx.frontmatter;
  const featuredImgFluid = featuredImage.childImageSharp.fluid;
  let footerImgFluid;
  let footerImg2Fluid;
  let badgeImageFluid;
  if (footerImage) {
    footerImgFluid = footerImage.childImageSharp.fluid;
  }
  if (footerImage2) {
    footerImg2Fluid = footerImage2.childImageSharp.fluid;
  }
  if (badgeImage) {
    badgeImageFluid = badgeImage.childImageSharp.fluid;
  }
  const { next, prev } = pageContext;
  return (
    <StyledLayout white={whiteHeader} isTransparent={isTransparent}>
      <StyledImg
        fluid={featuredImgFluid}
        imgStyle={{
          objectFit: 'cover',
          objectPosition: 'center top',
        }}
      />
      {badgeImage && (
        <BadgeWrapper>
          <Img fluid={badgeImageFluid} />
        </BadgeWrapper>
      )}
      {whiteHeader && <Overlay />}
      <StyledHr />
      <TitleSection
        title={title}
        tags={tags}
        date={date}
        link={link}
        client={client}
        format={format}
        linkUrl={linkUrl}
        credits={credits}
      />
      <StyledHr />
      <MDXRenderer localImages={embeddedImagesLocal}>
        {data.mdx.body}
      </MDXRenderer>
      {footerImage && (
        <FooterImage
          fluid={footerImgFluid}
          className={footerImage2 && 'with-margin'}
        />
      )}
      {footerImage2 && <FooterImage fluid={footerImg2Fluid} />}
      {!footerImage && !footerImage2 && <BottomHr />}
      <LinksWrapper>
        <StyledBox>
          <div>
            <ArrowLink
              isLeft
              href={prev.fields.slug}
              text={prev.frontmatter.nextPrevLink}
              fontFamily={styledTheme.fonts.body}
            />
          </div>
        </StyledBox>
        <StyledBox>
          <div>
            <ArrowLink
              isLeft={false}
              href={next.fields.slug}
              text={next.frontmatter.nextPrevLink}
              fontFamily={styledTheme.fonts.body}
            />
          </div>
        </StyledBox>
      </LinksWrapper>
      <BottomHr />
    </StyledLayout>
  );
};
interface ProjectPageProps {
  data: any;
  pageContext: any;
}

const StyledLayout = styled(Layout)`
  margin-left: 0;
`;

const BottomHr = styled.hr`
  margin-bottom: 0;
`;

const StyledHr = styled.hr`
  margin-bottom: 0;
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -80px;
  @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
    height: 413px;
  }
  @media screen and (min-width: calc(${styledTheme.breakpoints[2]} + 1px)) {
    height: 550px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 230px;
  top: 0;
  left: 0;
  background-color: rgba(23, 27, 61, 0.2);
  @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
    height: 413px;
  }
  @media screen and (min-width: calc(${styledTheme.breakpoints[2]} + 1px)) {
    height: 550px;
  }
`;

const BadgeWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 50px;
  top: 140px;
  @media screen and (min-width: ${styledTheme.breakpoints[0]}) {
    width: 60px;
  }
  @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
    width: 80px;
    top: 260px;
  }
  @media screen and (min-width: calc(${styledTheme.breakpoints[2]} + 1px)) {
    width: 100px;
    top: 380px;
  }
`;

const FooterImage = styled(Img)`
  &.with-margin {
    margin-bottom: 60px;
  }
`;

const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const StyledBox = styled(Box)`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;

  &:first-of-type {
    justify-content: flex-end;
    padding: 26px 30px 26px 0;
    a {
      margin-left: 20px;
      text-align: end;
    }
  }

  &:last-of-type {
    padding: 26px 0 26px 30px;
    justify-content: flex-start;
    a {
      margin-right: 20px;
    }
  }

  > div {
    display: flex;
    align-items: center;

    a {
      font-size: 16px;
      line-height: 22px;
      max-width: 150px;

      @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
        font-size: 20px;
        max-width: none;
        line-height: 32px;
      }
    }
  }

  .left {
    margin-top: 3px;
    margin-right: 10px;

    @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
      margin-right: 43px;
    }
  }

  .right {
    margin-left: 10px;
    margin-top: 3px;

    @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
      margin-left: 43px;
    }
  }

  &:first-of-type {
    border-right: 1px solid #d6d8e0;
  }

  @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
    height: 152px;

    &:first-of-type {
      justify-content: flex-end;
      padding: 26px 70px 26px 0;
      a {
        margin-left: 20px;
        text-align: end;
      }
    }

    &:last-of-type {
      padding: 26px 0 26px 70px;
      justify-content: flex-start;
      a {
        margin-right: 20px;
      }
    }
  }

  @media screen and (max-width: ${styledTheme.breakpoints[0]}) {
    a {
      width: min-content;
    }
  }
`;

export default ProjectPage;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        format
        tags
        link
        client
        credits
        date(formatString: "YYYY")
        whiteHeader
        isTransparent
        withBadge
        nextPrevLink
        linkUrl
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
        badgeImage {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        footerImage {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      body
    }
  }
`;
