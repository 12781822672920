import React from 'react';
import { styledTheme } from '@/styledTheme';
import { Row } from '@/components/Row';
import styled from 'styled-components';
import { Heading, Text, Link, Flex } from 'rebass/styled-components';
import ArrowLink from '@/components/ArrowLink';

const TitleSection = ({
  title,
  tags,
  date,
  link,
  client,
  format,
  credits,
  linkUrl,
}: TitleSectionProps) => (
  <StyledRow
    flexProps={{
      mb: [0],
    }}
    left={
      <LeftWrapper>
        <div>
          <Heading
            fontSize={['32px', '32px', '36px']}
            fontWeight={700}
            fontFamily={styledTheme.fonts.body}
            pt={[3, 3, 4]}
            pr={[0, 0, 20]}
            lineHeight={['40px', '40px', '45.5px']}
          >
            {title}
          </Heading>
          <TagsWrapper>
            {tags.map((el, i) => (
              <Text
                fontSize={2}
                color="#a1a5b5"
                lineHeight={['24px']}
                fontFamily={styledTheme.fonts.heading}
              >
                {el}
                <span>&nbsp;/&nbsp;</span>
              </Text>
            ))}
          </TagsWrapper>
          <Text
            fontSize={2}
            color="#a1a5b5"
            lineHeight={['24px']}
            mb={[4, 4, '45px']}
            fontFamily={styledTheme.fonts.heading}
          >
            {date}
          </Text>
        </div>
        <Flex mb={[3, 3, 4]} mt={[0, 0, '35px', 0]}>
          <StyledArrowLink
            isLeft={false}
            href={linkUrl}
            text={link}
            targetBlank
            fontFamily={styledTheme.fonts.heading}
          />
        </Flex>
        <MobileHr />
      </LeftWrapper>
    }
    right={
      <RightWrapper>
        <RightWrapperElement>
          <SectionHeading>CLIENT</SectionHeading>
          <SectionContent>{client}</SectionContent>
          <SectionHeading className="format-title">FORMAT</SectionHeading>
          <SectionContent className="format-content">{format}</SectionContent>
        </RightWrapperElement>
        <RightWrapperElement>
          <SectionHeading>CREDITS</SectionHeading>
          {credits.map((el) => (
            <SectionContent
              className="credits-content"
              dangerouslySetInnerHTML={{ __html: el }}
            />
          ))}
        </RightWrapperElement>
      </RightWrapper>
    }
  />
);

interface TitleSectionProps {
  title: string;
  tags: Array<string>;
  date: string;
  link: string;
  client: string;
  format: string;
  credits: Array<string>;
  linkUrl: string;
}

const StyledRow = styled(Row)`
  > div {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${styledTheme.breakpoints[2]}) {
      flex-direction: row;
    }

    > div:first-of-type {
      width: 100%;

      @media screen and (min-width: ${styledTheme.breakpoints[2]}) {
        width: 33.33333333333333%;
        margin-bottom: 0;
      }
    }
    > div:last-of-type {
      width: 100%;

      @media screen and (min-width: ${styledTheme.breakpoints[2]}) {
        width: 66.66666666666666%;
      }
    }
  }
`;

const StyledArrowLink = styled(ArrowLink)`
  align-items: flex-start;
  @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
    height: 20px;
    padding-top: 90px;
  }
`;

const LeftWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${styledTheme
      .breakpoints[1]}) and (max-width: 1023px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (min-width: ${styledTheme.breakpoints[2]}) {
    border-right: 1px solid #d6d8e0;
  }
`;

const RightWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-left: 84px;
  padding-top: 60px;
  font-family: ${({ theme }) => theme.fonts.heading};
  flex-wrap: wrap;

  @media screen and (max-width: ${styledTheme.breakpoints[1]}) {
    flex-direction: column;
    padding-left: 0;
    padding-top: 40px;
    padding-bottom: 60px;
  }

  @media screen and (min-width: ${styledTheme
      .breakpoints[1]}) and (max-width: 1023px) {
    padding-top: 15px;
    padding-left: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

const RightWrapperElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-bottom: 60px;

  .credits-content {
    line-height: 25px;
  }

  &:first-of-type {
    margin-right: 20px;
  }

  @media screen and (max-width: ${styledTheme.breakpoints[1]}) {
    width: 100%;
    margin-bottom: 0;

    &:first-of-type {
      margin-right: 0;
    }
  }

  @media screen and (min-width: ${styledTheme
      .breakpoints[1]}) and (max-width: 1023px) {
    &:first-of-type {
      width: 33.33333333333333%;
    }
    &:last-of-type {
      width: 66.66666666666666%;
    }
  }
`;

const SectionHeading = styled(Text)`
  line-height: 19px;
  margin-bottom: 17px;

  &.format-title {
    margin-bottom: 18px;

    @media screen and (max-width: ${styledTheme.breakpoints[1]}) {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: ${styledTheme.breakpoints[1]}) {
    margin-bottom: 10px;
  }
`;

const SectionContent = styled(Text)`
  line-height: 24px;
  margin-bottom: 40px;
  color: #a1a5b5;
  &.credits-content {
    margin-bottom: 0;
  }
  &.format-content {
    margin-bottom: 0;

    @media screen and (max-width: ${styledTheme.breakpoints[1]}) {
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: ${styledTheme.breakpoints[1]}) {
    margin-bottom: 30px;
  }
`;

const MobileHr = styled.hr`
  margin: 0 -20px;
  @media screen and (min-width: ${styledTheme.breakpoints[1]}) {
    display: none;
  }
`;

const TagsWrapper = styled.div`
  display: flex;
  margin-top: 5px;

  div {
    &:last-of-type {
      span {
        display: none;
      }
    }
  }
  @media screen and (max-width: ${styledTheme.breakpoints[1]}) {
    margin-top: 0;
  }
`;

export default TitleSection;
